/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 20:57:20
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-05 01:22:22
 */
import React, { memo } from "react"
import { ImageItemWrapper } from "./style"

const ImageItem = memo((props) => {
  const { imgName, imgDesc, subtitle = "", width = 300, height = 300 } = props
  return (
    <ImageItemWrapper width={width} height={height}>
      <div className="desc">
        <div className="title">{imgDesc}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
      <div className="img-bg">
        <img
          className="img"
          src={require(`@/assets/images/powerwall/${imgName}`)}
          alt=""
        />
        <div className="overlay"></div>
      </div>
    </ImageItemWrapper>
  )
})

export default ImageItem
