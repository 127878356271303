/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:36:00
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-12 00:01:28
 */
import styled from "styled-components"

export const IntroductionWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    margin-top: 30px;
    .video {
      margin-top: 30px;
      width: 100%;
      height: ${(props) => (props.screenwidth * 0.9) / 1.5765}px;
    }
    .introduction {
      width: 100%;
      .desc {
        margin-top: 10px;
        .title {
          font-size: 28px;
          font-weight: 500;
          line-height: 28px;
        }

        .sub-title {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1400px) {
    margin-top: 50px;

    .introduction {
      position: relative;
      max-width: 641px;
      flex: 1;
      margin-left: 20px;
      .desc {
        position: absolute;
        top: 54px;
        .title {
          font-size: 40px;
          font-weight: 500;
          letter-spacing: -2px;
          line-height: 44px;
        }

        .sub-title {
          margin-top: 40px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 25.56px;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    margin-top: 222px;

    /* .video {
      width: 700px;
      height: 444px;
    } */
    .introduction {
      position: relative;
      max-width: 641px;
      flex: 1;
      margin-left: 59px;
      .desc {
        position: absolute;
        top: 94px;
        .title {
          font-size: 60px;
          font-weight: 500;
          letter-spacing: -2px;
          line-height: 64px;
        }

        .sub-title {
          margin-top: 80px;
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 25.56px;
        }
      }
    }
  }
`
