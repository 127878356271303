/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-02 07:23:48
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-02 07:33:38
 */
import styled from "styled-components"

export const TitleItemWrapper = styled.div``
