import React, { memo } from "react";
import { enquireScreen } from "enquire-js";
import "./index.less";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/swiper.min.css";
import logoImg from "@/assets/images/logo-new.png";
import { Modal, Icon } from "antd";

const home1Img = require("@/assets/images/presale/H1_BLACK.png");
const video2 = require("@/assets/images/presale/H1_V2.mp4");
const appleImg = require("@/assets/images/presale/app_store.png");
const googleImg = require("@/assets/images/presale/google_play.png");
const AptoideImg = require("@/assets/images/presale/aptoide@2x.png");
const grants1 = require("@/assets/images/presale/Grants1.png");
const grants2 = require("@/assets/images/presale/Grants2.png");
const grants3 = require("@/assets/images/presale/Grants3.png");
const qrcode1 = require("@/assets/images/presale/Android.png");
const qrcode2 = require("@/assets/images/presale/AppleQR.png");
const qrcode3 = require("@/assets/images/presale/aptoide.png");

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class Specifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      downloadVisible: false,
      downloadTitle: "Android",
      FAQVisible: false,
      isPlayed: 0
    };
  }
  componentDidMount = () => {
    let myVideo1 = document.getElementById("jzVideo1");
    if (isMobile) {
      setTimeout(() => {
        myVideo1.play();
      }, 500);
    }
    if (this.state.isPlayed === 0) {
      myVideo1.play();
      this.setState({
        isPlayed: this.state.isPlayed + 1,
      });
    }
    myVideo1.addEventListener("canplay", function (e) {
      myVideo1.play();
    });
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    window.scrollTo(0, 0);
  };
  openDownload = (type) => {
    if (isMobile) { 
      switch (type) {
        case 'Apple':
          window.location.href = 'https://apps.apple.com/us/app/starpower-network/id6473820984';
          break;
        case 'Android':
          window.location.href = 'https://www.starpower.world/static/download/starpower.apk';
          break;
        case 'Aptoide':
          window.location.href = 'https://starpower.en.aptoide.com/app?store_name=catappult&app_id=67587299';
          break;
        default:
          window.location.href = 'https://www.starpower.world/static/download/starpower.apk';
          break;
      }
    } else {
      this.setState({
        downloadVisible: true,
        downloadTitle: type,
      });
    }
  };
  cancelDownload() {
    this.setState({
      downloadVisible: false,
    });
  }
  openFAQ () {
    this.setState({
      FAQVisible: true,
    });
  }
  cancelFAQ () {
    this.setState({
      FAQVisible: false,
    });
  }
  render() {
    const productInfo = [
      {
        name: "dimensions",
        value: "160mm*95mm*82mm",
      },
      {
        name: "weight",
        value: "281g",
      },
      {
        name: "connectivity details",
        value: "Bluetooth  / Wi-Fi with 2.4GHz",
      },
      {
        name: "input",
        value: "100-240VAC  50/60Hz",
      },
      {
        name: "max power",
        value: "1400VA at 110V / 220VA at 220V",
      },
      {
        name: "max current",
        value: "13A",
      },
    ];
    const bottomStyle = {
      borderBottom: "1px solid rgb(166, 166, 166)",
    };
    const faqList = [
      {
        title: "What's in the box?",
        conent:
          "Each Starplug box contains the Starplug smart device itself and a quick start guide to help you get it set up. Also included is information on how to download the Starpower app, which you'll use to manage your device and view your energy usage and rewards.",
      },
      {
        title: "How many Starplug devices can I purchase?",
        conent:
          "You can purchase up to 10 Starplug devices per order. If you need more than 10 units, please see below regarding bulk orders.",
      },
      {
        title: "Can I order more than 10 units?",
        conent:
          "Yes, if you're interested in placing a bulk order for more than 10 Starplug devices, please contact us via email. We'll assist you with your bulk purchase needs.",
      },
      {
        title: "Do you ship to my country?",
        conent:
          "We currently ship to countries in the European Union, the United States, and the Asia-Pacific region. If you're unsure whether we ship to your specific location, please contact our support team for more information.",
      },
      {
        title: "When will my Starplug be shipped?",
        conent:
          "We expect to begin shipping Starplug devices from late April, depending on your region. You'll receive a confirmation email with your shipping details and a tracking number once your order is dispatched.",
      },
      {
        title: "How do I cancel my order or return a product?",
        conent: [
          <span>
           You can cancel your order at any time before it has been shipped. If you wish to return a product, you may do so within 14 days of receiving your shipment. To cancel an order or initiate a return, please contact our customer service team with your order details.{" "}
          </span>,
          <br />,
          <span>
            For any additional questions or concerns, please don't hesitate to reach out to our support team. We're here to help make your Starplug experience as smooth and rewarding as possible.
          </span>,
        ],
      },
    ];
    return (
      <div className="starplug-detial">
        <div className="starplug-top-btn">
          <p className="starplug-btn-title">
            Plug and Play from <span className="starplug-title-price">$99</span>
          </p>
          <p className="starplug-btn-desc">
            The First Batch of Starplug Pre-Orders Has Successfully Closed!
          </p>
          <div></div>
          <Link className="starplug-btn-button" to="/support">
            User Guide
          </Link>
        </div>
        <div className="starplug-floor" id="hideTop">
          <div className="starplug-floor-video">
            <video
              className="floor-video-src"
              id="jzVideo1"
              muted
              loop
              playsInline
              autoPlay
              x5-video-player-type="h5"
              src={video2}
              poster={home1Img}
            />
          </div>
        </div>
        <div className="starplug-product" id="presaleProduct">
          <div className="starplug-product-content">
            <div className="starplug-product-title">
              <span>Starplug specifications</span>
            </div>
            <div className="starplug-product-info">
              <div className="starplug-info-img">
                <div className="starplug-detail-desc">
                  <p>
                    ESP32 Chip technology offering robust connectivity and
                    verifiability.
                  </p>
                  <p>Dual-core, multithreaded for lightning response.</p>
                  <p>Wireless/Bluetooth, APP-driven STAR Token mining.</p>
                  <p>Unique device identity.</p>
                  <p>
                    Monitor power use, remote control relays for trusted,
                    seamless plug-and-play.
                  </p>
                </div>
              </div>
              <div className="starplug-info-detail">
                {productInfo.map((item, index) => {
                  return (
                    <div
                      className="starplug-detail-item"
                      key={index}
                      style={
                        index == productInfo.length - 1 ? bottomStyle : null
                      }
                    >
                      <p>{item.name}</p>
                      <p>{item.value}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="starplug-open-FAQ">
              <button onClick={() => {
                  this.openFAQ();
                }} >FAQs about Starplug Pre-Order<Icon type="plus-circle" className="FAQ-circle" /></button>
            </div>
          </div>
        </div>
        <div className="starplug-download">
          <div className="starplug-download-content">
            <div className="starplug-download-title">
              <p><span>Download for Starpower mobile</span></p>
              <p>save our planet with climate technology.</p>
            </div>
            <div className="starplug-download-btn">
              <img
                src={appleImg}
                alt=""
                onClick={() => {
                  this.openDownload("Apple");
                }}
              />
              <img
                src={googleImg}
                alt=""
                onClick={() => {
                  this.openDownload("Android");
                }}
              />
              <img
                src={AptoideImg}
                alt=""
                onClick={() => {
                  this.openDownload("Aptoide");
                }}
              />
            </div>
            {isMobile ? (
              <Swiper
                style={{ width: "100%" }}
                className="starplug-swiper"
                spaceBetween={-30}
                slidesPerView="auto"
                centeredSlides={true}
              >
                <SwiperSlide style={{ width: "80%" }}>
                  <img src={grants3} alt="" />
                </SwiperSlide>
                <SwiperSlide style={{ width: "80%" }}>
                  <img src={grants2} alt="" />
                </SwiperSlide>
                <SwiperSlide style={{ width: "80%" }}>
                  <img src={grants1} alt="" />
                </SwiperSlide>
              </Swiper>
            ) : (
              <div className="starplug-download-info">
                <img src={grants1} alt="" />
                <img src={grants2} alt="" />
                <img src={grants3} alt="" />
              </div>
            )}
            <p className="starplug-download-bottom">
              Join the Starpower community to seamlessly integrate your
              electrical appliances through IoT technology, contribute to
              environmental sustainability, reduce your electricity costs, and
              earn carbon credits as rewards.
            </p>
          </div>
        </div>
        <Modal
          className="statplug-FAQ-modal"
          title=""
          width={isMobile ? "90%" : "80%"}
          centered
          mask={true}
          maskStyle={{backgroundColor: "#000", opacity: '0.9'}}
          footer={null}
          visible={this.state.FAQVisible}
          onCancel={() => this.cancelFAQ(false)}
        >
          <div className="starplug-FAQ">
            <div className="starplug-FAQ-content">
              <div className="starplug-FAQ-title">
                Frequently Asked Questions about Starplug Pre-Order
              </div>
              <div className="starplug-FAQ-list">
                {faqList.map((item, index) => {
                  return (
                    <div className="starplug-FAQ-item" key={index}>
                      <p className="starplug-FAQ-Q">{item.title}</p>
                      <p className="starplug-FAQ-A">{item.conent}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="statplug-download-modal"
          title=""
          width={isMobile ? "90%" : "50%"}
          centered
          mask={true}
          maskStyle={{backgroundColor: "#000", opacity: '0.9'}}
          footer={null}
          visible={this.state.downloadVisible}
          onCancel={() => this.cancelDownload(false)}
        >
          <div className="download-modal-detail">
            {this.state.downloadTitle == 'Aptoide' ? 
              (<p>Download from {this.state.downloadTitle}</p>) :
              (<p>Download for {this.state.downloadTitle} mobile</p>)
            }
            <img className="download-modal-logo" src={logoImg} alt="" />
            {this.state.downloadTitle == "Apple" ?
            (<img className="download-modal-qrcode" src={qrcode2} alt="" />) :
              (this.state.downloadTitle == "Android") ?
              (<img className="download-modal-qrcode" src={qrcode1} alt="" />) :
              (<img className="download-modal-qrcode" src={qrcode3} alt="" />)
            }
            <p style={{ fontWeight: "bold" }}>Your new trusted companion</p>
            <p>
              Scan the QR code or search for Starpower in the App Store or
              Google Play soon.
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}
export default Specifications;