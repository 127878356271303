/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:42:53
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-02 07:50:18
 */
import React, { memo } from "react"

import { ParticipationMethodsWrapper } from "./style"
import TitleItem from "@/components/title-item"
import ParticipationItem from "@/components/participation-item"

const ParticipationMethods = memo((props) => {
  return (
    <ParticipationMethodsWrapper>
      <TitleItem
        title="Participation Methods"
        subtitle="To participate in the Starbattery project, you can:"
      />
      <div className="participation-content">
        <ParticipationItem
          title="Purchase the Battery"
          subtitle="Use the link to buy the Starbattery, or contact local OEM suppliers for more information."
        />

        <ParticipationItem
          title="Connect to the VPP"
          subtitle="Link your battery to the local VPP service provider and participate in demand response."
        />

        <ParticipationItem
          title="Gain Revenue"
          subtitle="Earn fiat revenue through VPP operations, and access cloud mining assets through token distribution."
        />
      </div>
    </ParticipationMethodsWrapper>
  )
})

export default ParticipationMethods
