import styled from "styled-components"

export const FAQList = styled.div`
  @media screen and (max-width: 1000px) {
    margin-top: 40px;
    .desc {
      margin-bottom: 40px;
      .title {
        font-size: 28px;
        font-weight: 500;
        letter-spacing: -2px;
        line-height: 30px;
        max-width: 100% !important;
        &.gradient-text {
          color: #FFF !important;
        }
      }
    }
    .faq-item {
      border-bottom: 1px solid rgba(166, 166, 166, 1);
      margin-bottom: 12px;
      .faq-item-title {
        font-size: 1.2rem;
      }
      .faq-item-answer {
        color: rgba(166, 166, 166, 1);
        font-size: 1rem;
      }
    }
    .section-box {
      margin-bottom: 40px;
    }
  }

  @media screen and (min-width: 1000px) {
    margin-top: 50px;
    .desc {
      margin-bottom: 40px;
      .title {
        &.gradient-text {
          color: #FFF !important;
        }
      }
    }
    .faq-item {
      border-bottom: 1px solid rgba(166, 166, 166, 1);
      margin-bottom: 3rem;
      .faq-item-title {
        font-size: 1.2rem;
      }
      .faq-item-answer {
        color: rgba(166, 166, 166, 1);
        font-size: 1rem;
      }
    }
    .section-box {
      margin-bottom: 160px;
    }
  }
`