/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:42:53
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-05 00:10:21
 */
import styled from "styled-components"

export const ProjectGoalsWrapper = styled.div`
  @media screen and (max-width: 1000px) {
    margin-top: 40px;

    .img-box {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      .main-img {
        width: 100%;
        margin-bottom: 20px;
      }
      .sub-img {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div {
          margin-bottom: 10px;
        }
      }
    }
  }
  @media screen and (min-width: 1001px) and (max-width: 1290px) {
    margin-top: 275px;
    margin-bottom: 100px;
    .desc {
      position: absolute;
      width: calc(100% - 100px);
      top: 40px;
      left: 50px;
      z-index: 2;
      .title {
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 46.01px;
        &:hover {
          text-decoration: none;
        }
      }
      .subtitle {
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 30.67px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .img-box {
      display: flex;
      justify-content: center;
      margin-top: 40px;

      .main-img {
        margin-right: 10px;
      }
      .sub-img {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .img-bg{
        margin-top: 10px;
      }
    }
  }
  @media screen and (min-width: 1291px) {
    margin-top: 275px;
    .desc {
      position: absolute;
      width: calc(100% - 100px);
      top: 40px;
      left: 50px;
      z-index: 2;
      .title {
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 46.01px;
        &:hover {
          text-decoration: none;
        }
      }
      .subtitle {
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 30.67px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .img-box {
      display: flex;
      margin-top: 40px;

      .main-img {
        margin-right: 10px;
      }
      .sub-img {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .img-bg{
        margin-top: 10px;
      }
    }
  }
`
