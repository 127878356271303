/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:42:53
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-11 23:59:43
 */
import React, { memo, useEffect, useState } from "react"
import { ProjectGoalsWrapper } from "./style"
import TitleItem from "@/components/title-item"
import ImageItem from "@/components/image-item"

const screenwidth = window.innerWidth
const ProjectGoals = memo((props) => {
  const [images, setImages] = useState({
    image1: {
      width: 0,
      height: 0
    },
    image2: {
      width: 0,
      height: 0
    },
    image3: {
      width: 0,
      height: 0
    }
  })

  useEffect(() => {
    let imageObj = {}
    const baseW = screenwidth * 0.9 - 10
    if (screenwidth < 1000) {
      imageObj = {
        image1: {
          width: screenwidth * 0.9,
          height: (screenwidth * 0.9) / 1.29
        },
        image2: {
          width: screenwidth * 0.9,
          height: (screenwidth * 0.9) / 1.61
        },
        image3: {
          width: screenwidth * 0.9,
          height: (screenwidth * 0.9) / 2.81
        }
      }
    } else if (screenwidth < 1400) {
      imageObj = {
        image1: {
          width: baseW * 0.52,
          height: (baseW * 0.52) / 1.29
        },
        image2: {
          width: baseW * 0.53,
          height: (((baseW * 0.53) / 1.35) * 2) / 3
        },
        image3: {
          width: baseW * 0.53,
          height: (((baseW * 0.53) / 1.35) * 1) / 3
        }
      }
    } else {
      imageObj = {
        image1: {
          width: "783",
          height: "607"
        },
        image2: {
          width: "607",
          height: "378"
        },
        image3: {
          width: "607",
          height: "216"
        }
      }
    }
    setImages(imageObj)
  }, [])

  return (
    <ProjectGoalsWrapper>
      <TitleItem
        title="Project Goals and Mechanisms"
        subtitle="The goals and mechanisms of the Starbattery project include"
      />
      <div className="img-box">
        <div className="main-img">
          <ImageItem
            imgName="battery_1.png"
            imgDesc="Hardware and Technical Support"
            width={images.image1.width}
            height={images.image1.height}
          />
        </div>
        <div className="sub-img">
          <ImageItem
            imgName="battery_2.png"
            imgDesc="Virtual Power Plant Operation"
            width={images.image2.width}
            height={images.image2.height}
          />
          <ImageItem
            imgName="battery_3.png"
            imgDesc="Economic Model"
            width={images.image3.width}
            height={images.image3.height}
          />
        </div>
      </div>
    </ProjectGoalsWrapper>
  )
})

export default ProjectGoals
