/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:36:00
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-04 23:28:15
 */
import React, { memo, useState, useEffect } from "react";
import { IntroductionWrapper } from "./style";

const screenwidth = window.innerWidth;
const Introduction = memo((props) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  useEffect(() => {
    let w = 0
    let h = 0
    if (screenwidth < 1000) {
      w = screenwidth * 0.9
      h = w / 1.576
    } else if (screenwidth < 1400) {
      w = screenwidth * 0.5
      h = w / 1.576
    } else {
      w = 700
      h = 444
    }
    setWidth(w)
    setHeight(h)
  }, [])
  return (
    <IntroductionWrapper screenwidth={screenwidth}>
      <div className="video" width={width}>
        <img
          className="video-img"
          src={require("@/assets/images/evCharging/floor1.jpg")}
        />
      </div>
      <div className="introduction">
        <div className="desc">
          <div className="title gradient-text">Introduction</div>
          <div className="sub-title">
          Starcharger is designed to revolutionize home EV charging by leveraging blockchain technology, intelligent energy management, and secure token rewards. Star Alliance Program focuses on providing users with cutting-edge EV charging solutions while integrating them into a decentralized energy ecosystem.
          </div>
        </div>
      </div>
    </IntroductionWrapper>
  );
});

export default Introduction;
