import React from 'react';
import logoIcon_black from "../assets/images/logo-new.png";
import sidebarIcon from "../assets/images/sidebar-icon.png";
import { Icon } from "antd";

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper lvu02jzfxlb-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: "header0-logo",
    href: "/",
    children: logoIcon_black,
  },
  Menu: {
    className: 'header0-menu lvu054404cb-editor_css',
    children: [
      {
        name: 'item0',
        className: 'header0-item lvu046fku8-editor_css',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>Home</p>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'lvu08riitc-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item0',
        className: 'header0-item lvu046fku8-editor_css',
        children: {
          href: '/explorer',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>Explorer</p>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'lvu08riitc-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className:
          'header0-item ant-menu-submenu ant-menu-submenu-horizontal ant-menu-submenu-active ant-menu-submenu-open lvu022uivs-editor_css',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>Product<Icon type="caret-down" theme="filled" /></p>
                </span>
              ),
              name: 'text',
              className: 'lvu0aa9gxvr-editor_css',
            },
          ],
        },
        subItem: [
          {
            className: 'item-sub ant-menu-item lvtzvlvhhap-editor_css',
            children: {
              className: 'item-sub-item lvtzx1fse4-editor_css',
              children: [
                {
                  name: 'title~lvtzybmxwoe',
                  className: 'lvtzynsln4s-editor_css',
                  href: "/starplug",
                  children: (
                    <span>
                      <p>Starplug</p>
                    </span>
                  ),
                },
                {
                  name: 'title~lvtzyi0i4l6',
                  className: 'lvtzyt1fkzl-editor_css',
                  href: "/starbattery",
                  children: (
                    <span>
                      <p>Starbattery</p>
                    </span>
                  ),
                },
                {
                  name: 'title~lvtzyi0i4l6',
                  className: 'lvtzyt1fkzl-editor_css',
                  href: "/ev-charging",
                  children: (
                    <span>
                      <p>Starcharger</p>
                    </span>
                  ),
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item2',
        className: 'header0-item lvu09ws29k-editor_css',
        children: {
          href: '/docs',
          children: [
            {
              children: (
                <span>
                  <p>Docs</p>
                </span>
              ),
              name: 'text',
              className: 'lvu08ulsiw-editor_css',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item lvu07qzvyqg-editor_css',
        children: {
          href: '/stories',
          children: [
            {
              children: (
                <span>
                  <p>Stories</p>
                </span>
              ),
              name: 'text',
              className: 'lvu081gtuqs-editor_css',
            },
          ],
        },
      },
      {
        name: 'item~lvu0ubhm0pn',
        className: 'header0-item lvu07qzvyqg-editor_css',
        children: {
          href: '/usecase',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>Use cases</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'lvu081gtuqs-editor_css',
            },
          ],
        },
      },
      {
        name: 'item~lvu0ubhm0pn',
        className: 'header0-item lvu07qzvyqg-editor_css',
        children: {
          href: '/support',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>Support</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'lvu081gtuqs-editor_css',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: "header0-mobile-menu" },
  sidebar: {
    className: "sidebarIcon",
    children: sidebarIcon,
  },
};

