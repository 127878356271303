/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:47:09
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-05 00:56:59
 */
import React, { memo, useEffect, useState } from "react"
import { StakeholderBenefitsWrapper } from "./style.js"
import TitleItem from "@/components/title-item"
import ImageItem from "@/components/image-item"

const screenwidth = window.innerWidth
const StakeholderBenefits = memo((props) => {
  const [images, setImages] = useState({
    image1: {
      width: 0,
      height: 0,
    },
    image2: {
      width: 0,
      height: 0,
    },
    image3: {
      width: 0,
      height: 0,
    },
    image4: {
      width: 0,
      height: 0,
    },
  });
  useEffect(() => {
    let imageObj = {};
    const baseW = screenwidth * 0.9 - 10;
    if (screenwidth < 1000) {
      imageObj = {
        image1: {
          width: screenwidth * 0.43,
          height: (screenwidth * 0.43) / 0.63,
        },
        image2: {
          wwidth: screenwidth * 0.43,
          height: (screenwidth * 0.43) / 0.63,
        },
        image3: {
          width: screenwidth * 0.9,
          height: (screenwidth * 0.9) / 2.02,
        },
        image4: {
          width: screenwidth * 0.9,
          height: (screenwidth * 0.9) / 2.02,
        },
      };
    } else if (screenwidth < 1400) {
      imageObj = {
        image1: {
          width: baseW * 0.32,
          height: (baseW * 0.32) / 0.63,
        },
        image2: {
          width: baseW * 0.32,
          height: (baseW * 0.32) / 0.63,
        },
        image3: {
          width: baseW * 0.4,
          height: (baseW * 0.4) / 1.61,
        },
        image4: {
          width: baseW * 0.4,
          height: (baseW * 0.4) / 1.61,
        },
      };
    } else {
      imageObj = {
        image1: {
          width: "386",
          height: "610",
        },
        image2: {
          width: "386",
          height: "610",
        },
        image3: {
          width: "605",
          height: "300",
        },
        image4: {
          width: "605",
          height: "300",
        },
      };
    }
    setImages(imageObj);
  }, []);
  // const [images, setImages] = useState({
  //   image1: {
  //     width: screenwidth > 1400 ? "386" : screenwidth * 0.43,
  //     height: screenwidth > 1400 ? "610" : (screenwidth * 0.43) / 0.63
  //   },
  //   image2: {
  //     width: screenwidth > 1400 ? "386" : screenwidth * 0.43,
  //     height: screenwidth > 1400 ? "610" : (screenwidth * 0.43) / 0.63
  //   },
  //   image3: {
  //     width: screenwidth > 1400 ? "605" : screenwidth * 0.9,
  //     height: screenwidth > 1400 ? "300" : (screenwidth * 0.9) / 2.02
  //   },
  //   image4: {
  //     width: screenwidth > 1400 ? "605" : screenwidth * 0.9,
  //     height: screenwidth > 1400 ? "300" : (screenwidth * 0.9) / 2.02
  //   }
  // })
  return (
    <StakeholderBenefitsWrapper>
      <TitleItem
        title="Stakeholder Benefits"
        subtitle="The benefits for Starbattery stakeholders include:"
      />
      <div className="img-box">
        <ImageItem
          imgName="user.png"
          imgDesc="Battery Users"
          subtitle="Receive free or discounted batteries through initial subsidies, and enjoy long-term fiat revenue from VPP operations."
          width={images.image1.width}
          height={images.image1.height}
        />
        <ImageItem
          imgName="oem.png"
          imgDesc="OEM"
          subtitle="Expand market share through battery and battery management system sales."
          width={images.image2.width}
          height={images.image2.height}
        />
        <div className="two-img-box">
          <ImageItem
            imgName="starpower.png"
            imgDesc="Starpower"
            subtitle="Support token value through the first-year VPP fiat revenue and enhance brand image through sustainable energy solutions."
            width={images.image3.width}
            height={images.image3.height}
          />
          <ImageItem
            imgName="other_partners.png"
            imgDesc="Other Partners"
            subtitle="Increase customer loyalty and optimize energy resource allocation through VPP operations."
            width={images.image4.width}
            height={images.image4.height}
          />
        </div>
      </div>
    </StakeholderBenefitsWrapper>
  )
})

export default StakeholderBenefits
