import React, { memo } from "react"
import { FAQList } from "./style"

const FAQcontent = memo((props) => {
  const { FAQlist } = props
  return (
    <FAQList>
      <div className="desc">
        <div className="title gradient-text">Starplug Binding Q&A</div>
      </div>
      {FAQlist.map((itemData, index) => (
        <div className="faq-item">
          <p className="faq-item-title">{itemData.ques}</p>
          <p className="faq-item-answer">{itemData.ans}</p>
        </div>
      ))}
    </FAQList>
  )
})

export default FAQcontent