/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 20:57:20
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-12 00:14:35
 */
import React, { memo } from "react"
import { ParticipationItemWrapper } from "./style"

const screenwidth = window.innerWidth * 0.9
const ParticipationItem = memo((props) => {
  const { title, subtitle } = props
  return (
    <ParticipationItemWrapper width={(screenwidth - 20) / 3}>
      <div className="main-title">
        <img
          className="line"
          src={require("@/assets/images/powerwall/gradient-line.png")}
          alt=""
        />
        <span className="title">{title}</span>
      </div>
      <div className="sub-title">{subtitle}</div>
    </ParticipationItemWrapper>
  )
})

export default ParticipationItem
