/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:35:59
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-04 23:17:40
 */
import React, { memo } from "react"
import { BannerWrapper } from "./style"
import { enquireScreen } from "enquire-js";
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const Banner = memo((props) => {
  return (
    <BannerWrapper>
      <img
        className="banner-img"
        src={(isMobile) ? require("@/assets/images/powerwall/starplug-m2.jpg") : require("@/assets/images/presale/sp-banner.png")}
        alt=""
      />
      <div className="desc">
        <div className="title">Adjustable Load System</div>
        <div className="sub-title">
          Plug into the Future of Smart Energy Management by Starplug
        </div>
      </div>
    </BannerWrapper>
  )
})

export default Banner
