/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-04-30 16:06:13
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-04 22:26:38
 */
import React, { memo, useEffect } from "react"
import { TitleWrapper } from "./style"
const screenwidth = window.innerWidth
const UserGuide = memo((props) => {
  return (
    <TitleWrapper screenwidth={screenwidth}>
      <div className="title">
        <span className="normal-text">How to claim your device on </span>
        <span className="gradient-text"> dAPP</span>
      </div>
      <div className="video">
      <iframe
        className="ytb-video"
        width={(screenwidth > 1400 ? 1400 : screenwidth * 0.8)}
        height={(screenwidth > 1400) ? "800" : (screenwidth * 0.9) / 1.7475}
        src="https://www.youtube.com/embed/-XRcbOPz3QU?si=kEd0B6-E0m2VbIv3"
        title="Starpower"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      </div>
    </TitleWrapper>
  )
})
export default UserGuide
