/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-02 07:55:28
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-05 02:18:06
 */
import React, { Component } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { enquireScreen } from "enquire-js"
import Home from "./Home"
import Stories from "./Stories"
import ResetPwd from "./ResetPwd"
import Powerwall from "./views/Powerwall"
import UserGuide from "./views/User-guide"
import Usercase from "./Usecase"
import Header from './common/Header';
import Footer from './common/Footer';
import Starplug from './views/Starplug';
import EVCharging from './views/EVCharing';
import Docs from './Docs';
import Preorder from './Preorder';
import Explorer from './Explorer';

import {
  Nav00DataSource,
} from './common/data.source.js';

let isMobile
enquireScreen((b) => {
  isMobile = b
})

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile
    }
  }
  componentDidMount() {
    // Mobile screen;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    })
    // if (!isMobile) {
    //   window.addEventListener("resize", this.handleResize)
    // }
  }

  componentWillUnmount() {
    // if (!isMobile) {
    //   window.addEventListener("resize", this.handleResize)
    // }
  }

  // handleResize = () => {
  //   window.location.reload()
  // }
  render() {
    return (
      <Router basename="/">
        <div>
          <Header dataSource={Nav00DataSource} isMobile={this.state.isMobile} />
          <Route path="/stories" component={Stories} />
          <Route path="/resetpassword" component={(props) => <ResetPwd {...props}/>} />
          <Route path="/support" component={(props) => <UserGuide {...props}/>} />
          {/* <Route path="/powerwall" component={(props) => <Powerwall {...props}/>} /> */}
          <Route path="/starbattery" component={(props) => <Powerwall {...props}/>} />
          <Route path="/usecase" component={(props) => <Usercase {...props}/>} />
          <Route path="/starplug" component={(props) => <Starplug {...props}/>} />
          <Route path="/ev-charging" component={(props) => <EVCharging {...props}/>} />
          <Route path="/docs" component={(props) => <Docs {...props}/>} />
          <Route exact path="/" component={(props) => <Home {...props}/>} />
          <Route exact path="/explorer" component={(props) => <Explorer {...props}/>} /> 
          <Route path="/preorder" component={Preorder} />
          <Footer isMobile={this.state.isMobile} />
        </div>
      </Router>
    )
  }
}

export default App
