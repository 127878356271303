import styled from "styled-components"

export const TopHead = styled.div`
  text-align: center;
  .title {
    font-weight: 500;
    letter-spacing: -2px;
    .gradient-text {
      background: linear-gradient(
        90deg,
        rgb(55, 244, 167) 0%,
        rgb(64, 160, 238) 50%,
        rgb(158, 13, 255) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
  }
  .top-list {
    margin: 200px 0;
    display: flex;
    justify-content: space-between;
    .top-list-item {
      width: 200px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .img{
        width: 100px;
      }
      .list-item1 {
        color: #FFF;
        font-size: 1.2rem;
        margin: 0;
      }
      .list-item2 {
        color: rgba(166,166,166,1);
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .title {
      width: 100%;
      max-width: 100% !important;
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      letter-spacing: -2px;
      line-height: 30px;
    }

    .top-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 2rem 0 !important;
      .top-list-item {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin-bottom: 1.5rem;
        .img{
          width: 100px;
        }
        .list-item1 {
          width: 200px;
          color: #FFF;
          font-size: 1.2rem;
          margin: 0;
        }
        .list-item2 {
          width: 200px;
          color: rgba(166,166,166,1);
          font-size: 1rem;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .title {
      font-size: 60px;
      font-weight: 500;
      letter-spacing: -2px;
      line-height: 62px;
    }

    .top-list {
      margin: 200px 0;
      display: flex;
      justify-content: space-between;
      .top-list-item {
        width: 200px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        .img{
          width: 100px;
        }
        .list-item1 {
          color: #FFF;
          font-size: 1.2rem;
          margin: 0;
        }
        .list-item2 {
          color: rgba(166,166,166,1);
          font-size: 1rem;
        }
      }
    }
  }
`
