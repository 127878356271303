/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-04-30 16:34:49
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-04 22:44:02
 */
import styled from "styled-components"

export const TitleWrapper = styled.div`
  text-align: center;
  .title {
    font-weight: 500;
    letter-spacing: -2px;
    text-align: left;

    .gradient-text {
      background: linear-gradient(
        90deg,
        rgb(55, 244, 167) 0%,
        rgb(64, 160, 238) 50%,
        rgb(158, 13, 255) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
  }
  .video {
    margin: 0 auto;
    margin-top: 40px;
    background-color: rgb(56, 56, 56);
    border-radius: 15px;
    iframe {
      border-radius: 15px;
      border: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .title {
      font-size: 28px;
      font-weight: 500;
      letter-spacing: -2px;
      line-height: 30px;
      max-width: 100% !important;
    }

    .video {
      width: 90%;
      height: ${(props) => (props.screenwidth * 0.9) / 1.7475}px;
    }
  }

  @media screen and (min-width: 1200px) {
    .title {
      font-size: 60px;
      font-weight: 500;
      letter-spacing: -2px;
      line-height: 62px;
    }

    .video {
      width: 100%;
      max-width: 1398px;
      height: 800px;
    }
  }
`
