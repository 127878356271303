/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-04-30 16:06:24
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-04 22:11:23
 */

import styled from "styled-components"

export const PowerWallWrapper = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  overflow-x: hidden;
  .content {
    padding-top: 108px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1200px) {
    .content {
      width: 90%;
    }
  }
  @media screen and (min-width: 1200px) {
    .content {
      width: 100%;
      max-width: 1400px;
    }
  }
`
