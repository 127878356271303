/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 20:57:20
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-02 07:25:43
 */
import React, { memo } from "react"
import { TitleItemWrapper } from "./style"

const TitleItem = memo((props) => {
  const { title, subtitle } = props
  return (
    <TitleItemWrapper>
      <div className="title gradient-text">{title}</div>
      <div className="sub-title">{subtitle}</div>
    </TitleItemWrapper>
  )
})

export default TitleItem
