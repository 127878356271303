
export const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};
export const itemList = [
  {
    desc: "\"Our electricity supply system was designed for fossil fuel generators - it's not well-suited for the energy sources of the future. That needs to change, and virtual power plants are a way to maximise the benefits of solar.”",
    name: "Prof. Martin Green",
    position: "Scientia Professor at UNSW",
    imgSrc: require("../assets/images/presale/Prof. Martin Green.png"),
  },
  {
    desc: '"Starpower Vision, through its unique business model and technology, is enabling decarbonization down to the individuals. It\'s an amazing business and one that can potentially change the behavior of energy usage among consumers."',
    name: "Kang Jen Wee",
    position: "REDEX CEO",
    imgSrc: require("../assets/images/presale/Kang Jen Wee.png"),
  },
  {
    desc: '"Starpower is a representative case of DePIN, bringing Web3 into the trillion dollar energy market. "',
    name: "Dr. Xiao Feng",
    position: "Chairman of HashKey Group",
    imgSrc: require("../assets/images/presale/Dr. Xiao Feng.png"),
  },
  {
    desc: '"Starpower is taking on climate change with Web3, and honestly, the last time I was this pumped was when I put my money into Ethereum."',
    name: "Shen Bo",
    position: "Founder of Fenbushi Capital",
    imgSrc: require("../assets/images/presale/Shen Bo.png"),
  },
  {
    desc: '" IoTeX serves as the foundation for DePIN, and we are willing to collaborate with Starpower to address climate change and build an Energy Internet. "',
    name: "Raullen Chai",
    position: "Founder of IoTeX",
    imgSrc: require("../assets/images/presale/Raullen Chai.png"),
  },
  {
    desc: '"The Starpower team is ready to scale a global network of energy devices."',
    name: "Salvador Gala",
    position: "Founder of Escape Velocity",
    imgSrc: require("../assets/images/presale/Salvador Gala.png"),
  },
];
export const swiperFirst = [
  {
    title:
      "August 1st Starpower Happy Hour EventRaffle Results",
    desc: "The Starpower Happy Hour event on August 1st was a great success! We want to thank everyone who participated and made it an exciting experience. The list of winners has been posted. Click Learn More to see if you're one of the lucky winners. Stay tuned for more events and updates!",
    imgSrc: require("../assets/images/presale/item4.png"),
    href: window.location.origin + '/history',
  },
  {
    title:
      "Starpower Partners with WEIHENG to Develop Smart Energy Storage Technology",
    desc: "Weiheng Intelligent Technology Co., Ltd. (WEIHENG) and Starpower Network Co., Ltd. (Starpower Network) have announced a collaboration to jointly develop smart energy storage system (ESS) technology.",
    imgSrc: require("../assets/images/presale/item0.png"),
    href: "https://hk.finance.yahoo.com/news/starpower-partners-weiheng-develop-smart-020000014.html?guccounter=2",
  },
  {
    title:
      "Elon Musk: Al will run out of electricity and transformers in 2025",
    desc: "Elon Musk gave a closing Q&A (albeit a remote one) at the Bosch Connected World conference.",
    imgSrc: require("../assets/images/presale/item1.png"),
    href: "https://newatlas.com/technology/elon-musk-ai/",
  },
  {
    title:
      "OpenAl CEO Altman says at Davos future Al depends on energy breakthrough",
    desc: "OpenAI’s CEO Sam Altman said at a forum held at the Davos conference in Switzerland that an energy breakthrough is necessary for future artificial intelligence.",
    imgSrc: require("../assets/images/presale/item2.png"),
    href: "https://www.reuters.com/technology/openai-ceo-altman-says-davos-future-ai-depends-energy-breakthrough-2024-01-16/",
  },
  {
    title: "Big Tech's Latest Obsession Is Finding Enough Energy",
    desc: "The Al boom is fueling an insatiable appetite for electricity, which iscreating risks to the grid and the transition to cleaner energy sources.",
    imgSrc: require("../assets/images/presale/item3.png"),
    href: "https://www.wsj.com/business/energy-oil/big-techs-latest-obsession-is-finding-enough-energy-f00055b2",
  },
];
export const eventsList = [
  {
    type: 'blockTwo',
    itemsTypes: 'top',
    items: [
      {
        title: 'Dubai Token 2049',
        desc: 'Hosted by Starpower, joined with Solana MCM, BNB Chain, IoTeX, Alliance DAO, HashKey Capital, Bas1s Ventures, EV3.'
      },
      {
        imgSrc: require("../assets/images/img-event/DB1.jpg")
      }
    ]
  },
  {
    type: 'bigImg',
    imgSrc: require("../assets/images/img-event/DB2.jpg")
  },
  {
    type: 'halfImg',
    imgSrc: require("../assets/images/img-event/DB3.jpg")
  },
  {
    type: 'blockTwo',
    itemsTypes: 'down',
    items: [
      {
        imgSrc: require("../assets/images/img-event/HK1.jpg")
      },
      {
        title: 'Hong Kong Web3 Festival',
        desc: 'Solana Ecosystem Party DePIN panel'
      },
    ]
  },
  {
    type: 'bigImg',
    imgSrc: require("../assets/images/img-event/HK2.jpg")
  },
  {
    type: 'halfImg',
    imgSrc: require("../assets/images/img-event/HK3.jpg")
  },
  {
    type: 'blockTwo',
    itemsTypes: 'top',
    items: [
      {
        title: 'ETH Denver 2024',
        desc: 'DePIN Meetup ETH Denver'
      },
      {
        imgSrc: require("../assets/images/img-event/Denver1.jpg")
      }
    ]
  },
  {
    type: 'bigImg',
    imgSrc: require("../assets/images/img-event/Denver2.jpg")
  },
  {
    type: 'blockTwo',
    itemsTypes: 'img',
    items: [
      {
        imgSrc: require("../assets/images/img-event/Denver3.jpg")
      },
      {
        imgSrc: require("../assets/images/img-event/Denver4.jpg")
      }
    ]
  }
]