/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:32:49
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-05 00:49:18
 */
import React, { memo } from "react"
import { PowerwallWrapper } from "./style"

import Banner from "./c-cpns/banner"
import Introduction from "./c-cpns/introduction"
import ProjectGoals from "./c-cpns/project-goals"
const Powerwall = memo((props) => {
  window.scrollTo(0,0);
  return (
    <PowerwallWrapper>
      <Banner />
      <div className="content">
        <Introduction />
        <ProjectGoals />
      </div>
    </PowerwallWrapper>
  )
})

export default Powerwall
