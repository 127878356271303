import React from "react";
import "./less/footer.less";
import axios from 'axios';
import { notification } from 'antd';

class Footer extends React.Component {
  static defaultProps = {
    className: "footer1",
  };
  submitInfo = () => {
    // get email
    let email = document.getElementById('email').value;
    let params = {
      email: email,
      name: null,
      type: "A"
    }
    axios.post('/starpower/api/visitor/regist', params).then((res) => {
      console.log(params);
      localStorage.setItem("info_id", res.data.data);
      notification.success({
        top: 70,
        duration: 1.5,
        placement: 'topLeft',
        message: 'Succress',
        description:
          'Submit Succress',
        onClick: () => {
          console.log('close');
        },
      })
      document.getElementById('email').value = '';
    }).catch((error) => {
      notification.error({
        top: 24,
        duration: 1.5,
        placement: 'topLeft',
        message: 'Error',
        description: error,
        onClick: () => {
          console.log('close');
        },
      })
    });
  }
  render() {
    const links = [
      {
        icon: require("../../assets/images/home/discord.svg"),
        href: "https://discord.gg/starpowernetwork",
        name: "Discord",
      },
      {
        icon: require("../../assets/images/home/telegram.svg"),
        href: "https://t.me/starpowernetwork",
        name: "Telegram",
      },
      {
        icon: require("../../assets/images/home/twitter.svg"),
        href: "https://twitter.com/starpowerworld",
        name: "Twitter",
      },
      {
        icon: require("../../assets/images/home/youtube.svg"),
        href: "https://www.youtube.com/@StarpowerWorld",
        name: "Youtube",
      },
    ];
    return (
      <div className="footer">
        <div className="footer-content">
          <div className="footer-title">Join Starpower <span>Community</span></div>
          <div className="footer-Subscribe">
            <input id='email' className='centerSearch' type="text" placeholder='hi@starpower.world'></input>
            <button onClick={() => this.submitInfo()}>Subscribe</button>
          </div>
          <div className="footer-link">
            <div className="footer-link-list">
              {links.map((item, index) => {
                return (
                  <a href={item.href} target="_blank" key={index} >
                    <img src={item.icon} alt={item.name}/>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="footer-gitbook">
            <a href="#!">
              <p className="footer-link-copy">&copy; 2024 Starpower</p>
            </a>
            <a href='https://starpower.gitbook.io/starpower-terms-of-service/' target="_blank" >
              <p>Terms of Service</p>
            </a>
            <a href='https://starpower.gitbook.io/starpower-privacy-policy/' target="_blank" >
              <p>Privacy Policy</p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
