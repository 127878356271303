/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-02 07:23:48
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-12 00:13:28
 */
import styled from "styled-components"

export const ParticipationItemWrapper = styled.div`
  opacity: 1;
  border-radius: 15px;
  background: rgba(14, 16, 18, 1);

  @media screen and (max-width: 1000px) {
    width: 100%;
    height: 100px;
    margin-bottom: 12px;
    padding: 12px;
    .main-title {
      display: flex;
      align-items: center;
      .line {
        width: 2.52px;
        height: 20px;
        margin-right: 5px;
      }
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24.01px;
      }
    }
    .sub-title {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1400px) {
    width: ${(props) => props.width}px;
    padding: 20px;

    .main-title {
      display: flex;
      align-items: center;
      .line {
        width: 2.52px;
        height: 23px;
        margin-right: 5px;
      }
      .title {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 26.01px;
      }
    }
    .sub-title {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 16.56px;
    }
  }

  @media screen and (min-width: 1400px) {
    width: 450px;
    height: 320px;
    padding: 70px 50px 0 50px;

    .main-title {
      display: flex;
      align-items: center;
      .line {
        width: 2.52px;
        height: 33px;
        margin-right: 5px;
      }
      .title {
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 46.01px;
      }
    }
    .sub-title {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 25.56px;
    }
  }
`
