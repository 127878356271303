import React from "react";
import { enquireScreen } from "enquire-js";
import "./less/stories.less";
import { Link } from "react-router-dom";
const story_video = require("../assets/images/stories/story_video.png");
const story_1 = require("../assets/images/stories/cover1.png");
const story_2 = require("../assets/images/stories/cover2.png");
const story_3 = require("../assets/images/stories/cover3.png");
const story_4 = require("../assets/images/stories/cover4.png");
const story_5 = require("../assets/images/stories/cover5.png");

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

export default class Stories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      winWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="stories-wrapper">
        <div className="stories-content">
          <div className="header">
            <div className="header_title">
              The Starpower <span>Stories</span>
            </div>
            <div className="header-tips">
              Exploring the innovation and vision driving our journey towards a
              sustainable and interconnected energy future.
            </div>
          </div>
          <div className="stroy-main">
            <div className="conment">
              <div className="conment-left">
                {/* <img className="show-images" src={story_video} alt="" /> */}
                <iframe
                  width={
                    isMobile
                      ? this.state.winWidth * 0.9
                      : this.state.winWidth / 2.85
                  }
                  height={
                    isMobile
                      ? this.state.winWidth / 2
                      : (this.state.winWidth / 2.85 / 560) * 315
                  }
                  src="https://www.youtube.com/embed/L2Qi0j-BWKY?si=FLOG7UcQQmf_CjSz"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="conment-right">
                <div className="starpower_font">Starpower</div>
                <div className="starpower-tips">
                  It's Time to Take Action with Starpower
                </div>
                <div className="starpower-main">
                  Discover Starpower's green mission. We're more than just a
                  virtual power plant network; we're a synergy of sustainability
                  and technology. By connecting energy devices worldwide, we're
                  collectively easing the planet's burden. Save to earn, and it
                  is time to respond!
                </div>
                <Link to="/docs">
                  <div className="learn-bottom">LEARN MORE</div>
                </Link>
              </div>
            </div>
            <div className="conment2">
              <a
                className="item item-1"
                href="https://medium.com/@darcyjia/decentralized-physical-infrastructure-networks-and-starpower-enhancing-virtual-power-plant-a53c0ced9aad"
                target="_blank"
              >
                <div className="image-container">
                  <img className="show-images" src={story_1} alt="" />
                </div>
                <div className="source-name">Dr.Darcy Jia</div>
                <div className="instructions">
                  Decentralized Physical Infrastructure Networks and Starpower
                </div>
                <div className="instructions-2">
                  The advancement of Virtual Power Plants (VPPs) is critical in
                  the transition to a more sustainable and resilient energy
                  system. This article examines the role of Decentralized
                  Physical Infrastructure Networks (DePIN) and ...
                </div>
              </a>
              <a
                className="item"
                href="https://www.economist.com/technology-quarterly/2023/04/05/the-electric-grid-is-about-to-be-transformed"
                target="_blank"
              >
                <div className="image-container">
                  <img className="show-images" src={story_2} alt="" />
                </div>
                <div className="source-name">Technology Quarterly</div>
                <div className="instructions">
                  The electric grid is about to be transformed
                </div>
                <div className="instructions-2">
                  In the turbine hall at Drax, a power plant in the English
                  county of Yorkshire, sit six monsters of angular momentum.
                  They are massive—2,800 tonnes each—and complex, composed of
                  28...
                </div>
              </a>
              <a
                className="item"
                href="https://www.usv.com/writing/2021/06/electrifying-buildings/"
                target="_blank"
              >
                <div className="image-container">
                  <img className="show-images" src={story_3} alt="" />
                </div>
                <div className="source-name">Hannah Murdoch</div>
                <div className="instructions">Electrifying Buildings</div>
                <div className="instructions-2">
                  Electrifying buildings will play a critical role in managing
                  our carbon budget. Nearly 18% of global greenhouse gas
                  emissions are attributed to powering residential and
                  commercial properties (Figure 1).
                </div>
              </a>
              <a
                className="item item-1"
                href="https://medium.com/@darcyjia/revolutionizing-energy-with-starpower-a-depin-approach-to-virtual-power-plants-542a42e028ec"
                target="_blank"
              >
                <div className="image-container">
                  <img className="show-images" src={story_4} alt="" />
                </div>
                <div className="source-name">Dr.Darcy Jia</div>
                <div className="instructions">
                  Revolutionizing Energy with Starpower
                </div>
                <div className="instructions-2">
                  The advancement of Virtual Power Plants (VPPs) is critical in
                  the transition to a more sustainable and resilient energy
                  system.
                </div>
              </a>
              <a
                className="item"
                href="https://www.brattle.com/insights-events/publications/real-reliability-the-value-of-virtual-power/"
                target="_blank"
              >
                <div className="image-container">
                  <img className="show-images" src={story_5} alt="" />
                </div>
                <div className="source-name">Ryan Hledik</div>
                <div className="instructions">
                  VPPs Could Save US Utilities $15-$35 Billion in Capacity
                  Investment Over 10 Years
                </div>
                <div className="instructions-2">
                  A new study prepared for Google by energy analysts from The
                  Brattle Group explores the cost and ability to serve critical
                  resource adequacy needs from an emerging resource: virtual
                  power plants (VPPs).
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
