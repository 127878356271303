/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:36:00
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-11 22:45:28
 */
import React, { memo, useState, useEffect } from "react"
import { IntroductionWrapper } from "./style"

const screenwidth = window.innerWidth
const Introduction = memo((props) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  useEffect(() => {
    let w = 0
    let h = 0
    if (screenwidth < 1000) {
      w = screenwidth * 0.9
      h = w / 1.576
    } else if (screenwidth < 1400) {
      w = screenwidth * 0.5
      h = w / 1.576
    } else {
      w = 700
      h = 444
    }
    setWidth(w)
    setHeight(h)
  }, [])
  return (
    <IntroductionWrapper screenwidth={screenwidth}>
      <div className="video" width={width} height={height}>
        <iframe
          width={width}
          height={height}
          src="https://www.youtube.com/embed/6hItvH6oRB0?si=Oo-iDC4lmO7MVcok"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      <div className="introduction">
        <div className="desc">
          <div className="title gradient-text">Introduction</div>
          <div className="sub-title">
            Starbattery is a product from Starpower designed to advance energy
            network stability and sustainability through virtual power plant
            (VPP) solutions. By connecting batteries to a VPP, users can gain
            financial benefits while supporting the vision of a zero-carbon
            world.
          </div>
        </div>
      </div>
    </IntroductionWrapper>
  )
})

export default Introduction
