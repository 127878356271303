/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:35:59
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-04 23:17:40
 */
import React, { memo } from "react"
import { BannerWrapper } from "./style"
import { enquireScreen } from "enquire-js";
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const Banner = memo((props) => {
  return (
    <BannerWrapper>
      <img
        className="banner-img"
        src={(isMobile)? require("@/assets/images/powerwall/charger-m.jpg") : require("@/assets/images/evCharging/banner.jpg")}
        alt=""
      />
      <div className="desc">
        <div className="title">Electric Vehicle Charging</div>
        <div className="sub-title">
          Charge Ahead with DePIN-Powered Energy by Starcharger
        </div>
      </div>
    </BannerWrapper>
  )
})

export default Banner
