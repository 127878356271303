/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:42:53
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-05 01:13:07
 */
import styled from "styled-components"

export const StakeholderBenefitsWrapper = styled.div`
  @media screen and (max-width: 1300px) {
    margin-top: 30px;
    .img-box {
      display: flex;
      // flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;

      > div {
        margin-bottom: 20px;
      }

      .two-img-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  @media screen and (min-width: 1000px) and (max-width: 1299px) {
    margin-top: 30px;
    width: 95%;
    margin: 0 auto;
    .img-box {
      display: flex;
      // flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;

      > div {
        margin-bottom: 20px;
      }

      .two-img-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  @media screen and (min-width: 999px) {
    margin-top: 275px;
    .img-box {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      .two-img-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
`
