import React from "react";
import TweenOne from "rc-tween-one";
import { Menu, Modal } from "antd";
import { getChildrenToRender } from "./utils";
import NFTapply from "./NFTapply";
import logoImg from "../../assets/images/logo-new.png";
import { Link } from "react-router-dom";
import { enquireScreen } from "enquire-js";
import "./less/header.less";

const qrcode1 = require("../../assets/images/presale/Android.png");
const qrcode2 = require("../../assets/images/presale/AppleQR.png");
const qrcode3 = require("../../assets/images/presale/aptoide.png");
let isMobile;
if (window.innerWidth < 1366) {
  isMobile = true;
} else {
  enquireScreen((b) => {
    isMobile = b;
  });
}
const { Item, SubMenu } = Menu;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: false,
      menuHeight: 0,
      isPageLoading: false,
      headerStyle: "header-home",
      showNFT: false,
      downloadVisible: false,
      downloadTitle: "Android",
      pageY: 0,
    };
  }
  componentDidMount() {
    let header = document.getElementById("headerDom");
    let headerMsg = document.getElementById("headerMessage");
    // let that = this
    if (isMobile) {
      header.style.position = "fixed";
      header.style.zIndex = "99";
      // window.addEventListener("touchmove", function (ev) {
      //   let evPageY = ev.touches[0].pageY
      //   if (evPageY - that.state.pageY >= 0) {
      //     header.style.position = "relative";
      //   } else {
      //     header.style.position = "fixed";
      //   }
      //   that.setState({
      //     pageY: evPageY
      //   })
      // });
    } else {
      window.addEventListener("wheel", function (ev) {
        if (ev.deltaY > 0) {
          header.style.position = "relative";
        } else {
          header.style.position = "fixed";
          header.style.zIndex = "99";
        }
      });
    }
  }
  showNFTfunc = () => {
    this.setState({
      showNFT: true,
    });
  };
  closeNFTfunc = (value) => {
    this.setState({
      showNFT: value,
    });
  };
  openDownload = () => {
    this.setState({
      downloadVisible: true,
      downloadTitle: "Starpower",
    });
  };
  cancelDownload() {
    this.setState({
      downloadVisible: false,
    });
  }
  cancelApply() {
    this.setState({
      showNFT: false,
    });
  }
  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
      isPageLoading: true,
    });
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div
                {...a}
                className={`header0-item-block ${a.className}`.trim()}
              >
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header0-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <a {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </a>
              ) : (
                <div {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <a {...a} className={`header0-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </a>
        </Item>
      );
    });
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <div id="Nav0_0">
        <a href='https://starpower-market.myshopify.com/'>
          <div className="header-message" id="headerMessage">
            Starplug is Now Available for Order!
            <button>BUY NOW</button>
          </div>
        </a>
        <TweenOne
          component="header"
          animation={{ opacity: 0, type: "from" }}
          {...dataSource.wrapper}
          {...props}
          id="headerDom"
        >
          <div
            {...dataSource.page}
            className={`${dataSource.page.className}${
              phoneOpen ? " open" : ""
            }`}
          >
            <TweenOne
              animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
              {...dataSource.logo}
            >
              <Link to={dataSource.logo.href}>
                <img width="100%" src={dataSource.logo.children} alt="img" />
              </Link>
            </TweenOne>
            {isMobile && (
              <div
                {...dataSource.mobileMenu}
                onClick={() => {
                  this.phoneClick();
                }}
              >
                <em />
                <em />
                <em />
              </div>
            )}
            <TweenOne
              {...dataSource.Menu}
              animation={
                isMobile
                  ? {
                      height: 0,
                      duration: 300,
                      onComplete: (e) => {
                        if (this.state.phoneOpen) {
                          e.target.style.height = "auto";
                        }
                      },
                      ease: "easeInOutQuad",
                    }
                  : null
              }
              moment={moment}
              reverse={!!phoneOpen}
            >
              <Menu
                mode={isMobile ? "inline" : "horizontal"}
                defaultSelectedKeys={["sub0"]}
                theme="dark"
              >
                {navChildren}
              </Menu>
              {isMobile && (
                <div className={"moreBtn"}>
                  <button className="shopBtn" onClick={this.showNFTfunc}>
                    Get Rewards
                  </button>
                  <button
                    className="shopBtn"
                    onClick={() => {
                      this.openDownload("ALL");
                    }}
                  >
                    Download App
                  </button>
                </div>
              )}
            </TweenOne>
            {!isMobile && (
              <div className={"moreBtn"}>
                <button className="shopBtn" onClick={this.showNFTfunc}>
                  Get Rewards
                </button>
                <button
                  className={isMobile ? "hidden" : "shopBtn"}
                  onClick={() => {
                    this.openDownload("ALL");
                  }}
                >
                  Download App
                </button>
              </div>
            )}
          </div>
        </TweenOne>
        <Modal
          mask={true}
          maskStyle={{ backgroundColor: "#000", opacity: "0.9" }}
          title="Get Rewards"
          centered
          closable={false}
          footer={null}
          className="NFT-apply"
          destroyOnClose={true}
          maskClosable={true}
          onCancel={() => this.cancelApply(false)}
          visible={this.state.showNFT}
        >
          <NFTapply closeNFTfunc={this.closeNFTfunc} />
        </Modal>
        <Modal
          title=""
          width={isMobile ? "" : "50%"}
          centered
          mask={true}
          maskStyle={{ backgroundColor: "#000", opacity: "0.9" }}
          footer={null}
          visible={this.state.downloadVisible}
          onCancel={() => this.cancelDownload(false)}
          className="download-modal"
        >
          <div className="download-modal-content">
            <p>
              Download for{" "}
              <span className="download-modal-title">
                {this.state.downloadTitle}
              </span>{" "}
              mobile
            </p>
            <div className="download-qrcode-list">
              <div className="download-qrcode-item">
                <img className="download-modal-qrcode" src={qrcode2} alt="" />
                <p>iOS</p>
              </div>
              <div className="download-qrcode-item">
                <img className="download-modal-qrcode" src={qrcode1} alt="" />
                <p>Android</p>
              </div>
              <div className="download-qrcode-item">
                <img className="download-modal-qrcode" src={qrcode3} alt="" />
                <p>Aptoide</p>
              </div>
            </div>
            <p style={{ fontWeight: "bold" }}>Your new trusted companion</p>
            <p>
              Scan the QR code or search for Starpower in the App Store or
              Google Play soon.
            </p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Header;
