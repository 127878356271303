/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 12:51:51
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-04 22:57:42
 */
export const guideData = [
  {
    imgPath: "s1.png",
    title: "Step 1: Power on and charged",
    subtitles: [
      "·Power on the device and long press the button for 2-6 seconds.",
      "·Tap the <span class='weight'>“I’ve Charged My Device”</span> button."
    ],
    flexdirection: "row-reverse"
  },
  {
    imgPath: "s2.png",
    title: "Step 2: Connect to the Wi-Fi",
    subtitles: [
      "·Turn on your phone's Bluetooth and scan for Starpower devices（If not found, Please try to scan again）",
      "·Connect the Starpower device to your home Wi-Fi."
    ],
    flexdirection: "row"
  },
  {
    imgPath: "s3.png",
    title: "Step 3: Verify your device",
    subtitles: [
      "·Ensure the device’s public address and KYCODE is correct. (If loading device identifier fails, try to tap the “Load Again button)"
    ],
    flexdirection: "row-reverse"
  },
  {
    imgPath: "s4.png",
    title: "Step 4: Bind the device",
    subtitles: [
      "·Bind the device to your wallet and ensure the wallet address is correct."
    ],
    flexdirection: "row"
  },
  {
    imgPath: "s5.png",
    title: "Step 5: Set location",
    subtitles: [
      "·Set your location in the map so you canproceed with claiming the device."
    ],
    flexdirection: "row-reverse"
  }
]
export const FAQlist = [
  {
    ques: 'What preparations are needed before binding a device? ',
    ans: "Users should plug in the Starplug device and press and hold the power button for 2-6 seconds (ensure the timing is strictly controlled) until the button's light starts flashing. Users should enable Bluetooth on their phone and grant location permissions to the Starpower dAPP."
  },
  {
    ques: 'When is it necessary to rebind a device? ',
    ans: "If users encounter unresolved issues during the binding process or while using the device, they can try rebinding the device. Users should re-plug the Starplug device, press and hold the power button for 2-6 seconds until the light flashes, and return to the first step of the binding process in the dAPP to complete the following steps."
  },
  {
    ques: "In the Connection step, why can't the Starplug device be found after enabling Bluetooth? ",
    ans: "Bring the phone close to the device and wait patiently. If the device is not found after a long time, try turning Bluetooth off and on again and rescanning. If the device is still not found, re-plug the Starplug device, press and hold the power button for 2-6 seconds until the light flashes, then try rescanning."
  },
  {
    ques: 'In the Connection step, why is there no response or connection established after selecting the Starplug device from the Bluetooth search list?',
    ans: "Try turning Bluetooth off and on again and rescanning. If the issue persists, re-plug the Starplug device, press and hold the power button for 2-6 seconds until the light flashes, scan for the device and establish the connection."
  },
  {
    ques: 'In the Connection step, why is no Wi-Fi found? ',
    ans: 'Try tabing the "Scan Again" button. If no Wi-Fi is found, retry the device binding process.'
  },
  {
    ques: 'What if the Wi-Fi password is entered incorrectly in the Connection step? ',
    ans: 'Retry the device binding process.'
  },
  {
    ques: 'In the Verify step, why is the device identity not obtained? ',
    ans: 'Obtaining the device identity usually takes about 2 minutes. Please wait patiently. If it times out, retry. If multiple retries fail, retry the device binding process.'
  },
  {
    ques: 'In the Bind step, what if the device is already bound? ',
    ans: 'The device is already claimed by another wallet. Please use a new device to complete the binding.'
  },
  {
    ques: 'What if the device is not certified? ',
    ans: "Uncertified devices cannot complete the binding. Please contact the project's support team."
  },
  {
    ques: 'What if the claiming result fails? ',
    ans: 'Please contact the our support team.'
  },
  {
    ques: 'How can I know if my plug is working properly? ',
    ans: 'The app shows the status of connected plugs. "Online" indicates normal operation, while "Offline" and "Error" indicate disconnection.'
  },
  {
    ques: 'What should I do if a plugged-in, already bound Starplug shows as Offline?',
    ans: 'Retry binding the device.'
  },
  {
    ques: 'What should I do if the Wi-Fi password has changed for a bound Starplug? ',
    ans: 'Retry the device binding process.'
  },
]
