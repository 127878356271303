/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 20:57:20
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-05 02:19:31
 */
import styled from "styled-components"

export const ImageItemWrapper = styled.div`
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.71) 100%
    );
    opacity: 1;
    transition: opacity 0.5s ease;
    &:hover .overlay {
      opacity: 0.8;
      background: #0f0;
    }
  }

  &:hover .overlay {
    opacity: 0;
  }

  @media screen and (max-width: 1200px) {
    .desc {
      z-index: 9;
      position: absolute;
      padding: 16px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        &:hover {
          text-decoration: none;
        }
      }
      .subtitle {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .img-bg {
      position: relative;
      border-radius: 15px;
      overflow: hidden;
      width: ${(props) => props.width}px;
      height: ${(props) => props.height}px;

      .img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .desc {
      position: absolute;
      width: 316px;
      top: 40px;
      left: 50px;
      .title {
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 46.01px;
        &:hover {
          text-decoration: underline;
        }
      }
      .subtitle {
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 30.67px;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .img-bg {
      border-radius: 15px;
      overflow: hidden;
      width: ${(props) => props.width}px;
      height: ${(props) => props.height}px;

      .img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
      }
    }
  }
`
