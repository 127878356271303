/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-04-30 16:06:13
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-04 23:02:04
 */
import React, { memo } from "react"
import { PowerWallWrapper } from "./style"

import Title from "./c-cpns/title"
// import Content from "./c-cpns/main-content"
// import Congratulations from "./c-cpns/congratulations"
// import { guideData } from "./data/data"
import FAQList from "./c-cpns/faq-list"
import TopHead from "./c-cpns/top-head"
import { FAQlist } from "./data/data"

const UserGuide = memo((props) => {
  window.scrollTo(0,0);
  return (
    <PowerWallWrapper>
      <div className="content">
        <TopHead/>
        <Title />
        <FAQList FAQlist={FAQlist}/>
        {/* <Content guideData={guideData} />. */}
        {/* <Congratulations /> */}
      </div>
    </PowerWallWrapper>
  )
})

export default UserGuide
