/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:35:59
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-04 23:38:11
 */
import styled from "styled-components"

export const BannerWrapper = styled.div`
  position: relative;

  .banner-img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    .desc {
      position: absolute;
      top: 80px;
      left: 24px;

      .title {
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
      }

      .sub-title {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
    }
  } 
  @media screen and (min-width: 768px) and (max-width: 1000px) {
    .desc {
      position: absolute;
      top: 80px;
      left: 60px;

      .title {
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
      }

      .sub-title {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
  @media screen and (min-width: 1001px) and (max-width: 1289px) {
    .desc {
      position: absolute;
      top: 62%;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 24px;

      .title {
        width: 100%;
        margin-bottom: 10px;
        font-size: 50px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 58px;
      }

      .sub-title {
        width: 100%;
        font-size: 28px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 48px;
      }
    }
  }
  @media screen and (min-width: 1290px) {
    .desc {
      position: absolute;
      top: 62%;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .title {
        width: 100%;
        max-width: 1400px;
        margin-bottom: 10px;
        font-size: 60px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 64px;
      }

      .sub-title {
        width: 100%;
        max-width: 1400px;
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 64px;
      }
    }
  }
`
