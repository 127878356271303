/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:44:49
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-12 00:03:08
 */
import styled from "styled-components"

export const ParticipationMethodsWrapper = styled.div`
  @media screen and (max-width: 1000px) {
    margin-top: 30px;
    .participation-content {
      margin-top: 20px;
    }
  }

  @media screen and (min-width: 999px) and (max-width: 1299px) {
    margin: 0 auto;
    margin-top: 50px;
    width: 95%;
    .participation-content {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
    }
  }

  @media screen and (min-width: 1300px) {
    margin-top: 275px;
    .participation-content {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
    }
  }
`
