import React from "react";
import { enquireScreen } from "enquire-js";
import "./less/usercase.less";
const img1 = require("../assets/images/usercase/img1.png");
const img2 = require("../assets/images/usercase/img2.png");
const img3 = require("../assets/images/usercase/img3.png");
const img4 = require("../assets/images/usercase/img4.png");
const img5 = require("../assets/images/usercase/img5.png");

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

export default class Usercase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      list: [
        {
          imgSrc: img1,
          title: "Energy Efficiency for the 19th Asian Games",
          content: "In 2023, the 19th Asian Games witnessed an unparalleled fusion of sports and sustainability, thanks to Starpower's cutting-edge solutions. The Olympic Wanke Center, a key venue, embraced Starplug and Starbattery technologies, setting a new standard for managing electricity use in large-scale events. This strategic deployment led to an impressive reduction of over 10% in electricity consumption. Moreover, the initiative is on track to issue nearly a hundred carbon credits, marking a significant milestone in our journey towards environmental stewardship."
        },
        {
          imgSrc: img2,
          title: "REDEX Renewable Energy Certificates",
          content: "In a pioneering move to bolster the renewable energy landscape, Starpower teams up with REDEX, one of the foremost global institutions in renewable energy certificate issuance and Asia's largest renewable energy certificate exchange. This collaboration will unlock the potential of renewable energy certificates in residential settings, leveraging Starpower's vast network of potentially millions of residential users."
        },
        {
          imgSrc: img3,
          title: "Collaboration with AlphaESS Batteries",
          content: "Starpower and AlphaESS Batteries are at the forefront of transforming energy storage and virtual power plant technologies. AlphaESS, a leader in battery manufacturing globally and the top battery brand in Australia and Europe, collaborates with Starpower in the development of Starbattery. This partnership extends beyond manufacturing; it pioneers the integration of AlphaESS batteries into virtual power plants and carbon credit issuance. Through this innovative collaboration, hundreds of thousands of AlphaESS batteries are now capable of generating new revenue streams, demonstrating the vast potential of our combined technologies in advancing the green energy sector. "
        },
        {
          imgSrc: img4,
          title: "Karma Auto's Journey to Carbon Neutrality",
          content: "Karma Auto, an emblem of luxury and innovation in the electric supercar market, has embarked on a significant journey towards achieving carbon neutrality. By integrating Starpower's technology across its facilities, Karma Auto will enhance its sustainability efforts throughout its supply chain. Additionally, Starpower will offer a unique incentive program, providing carbon credits and rewards to Karma EV owners, further cementing Karma Auto's position as a leading environmentally friendly supercar brand."
        },
        {
          imgSrc: img5,
          title: "ShineHub and Virtual Power Plant Expansion",
          content: "ShineHub, Australia's premier virtual power plant service provider, has seen its capabilities and user base grow exponentially with Starpower's support. By incorporating Starplug and Starbattery aggregation into its operations, ShineHub is enhancing its virtual power plant services, providing more efficient and reliable energy solutions to nearly ten thousand users nationwide. This collaboration not only exemplifies the scalability and effectiveness of virtual power plants but also highlights Starpower's pivotal role in driving the expansion of sustainable energy solutions across Australia and beyond."
        },
      ],
      isLess1200: false,
    };
  }

  componentDidMount() {
    console.log('wind', window.innerWidth)
    this.setState({
      isLess1200: window.innerWidth <= 1200 ? true : false,
    })
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    window.scrollTo(0, 0)
  }

  render() {
    let { list, isLess1200 } = this.state;
    return (
      <div className="uc-wrapper">
        <div className="uc-content">
          <div className="uc-title">Starpower Use <span>Cases</span></div>
          <div className="uc-title-descibe">Discover how Starpower is driving sustainability and efficiency across the globe.</div>
          {
            isLess1200 ?
              <div className="uc-main">
                {
                  list.map((val, index) => {
                    return (
                      <div className="item" key={index}>
                        <img className='img' src={val.imgSrc} alt="" />
                        <div className='text'>
                          <div className="main-title">{val.title}</div>
                          <div className="main-content">{val.content}</div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
              :
              <div className="uc-main">
                {
                  list.map((val, index) => {
                    return (
                      <div className="item" key={index}>
                        {index % 2 === 0 ? <img className='img' src={val.imgSrc} alt="" /> : ''}
                        <div className={index % 2 === 0 ? 'text single' : 'text double'}>
                          <div className="main-title">{val.title}</div>
                          <div className="main-content">{val.content}</div>
                        </div>
                        {index % 2 === 0 ? '' : <img className='img' src={val.imgSrc} alt="" />}
                      </div>
                    );
                  })
                }
              </div>
          }
        </div>
      </div>
    );
  }
}
