/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:42:53
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-05 00:32:04
 */
import React, { memo, useEffect, useState } from "react";
import { ProjectGoalsWrapper } from "./style";
import TitleItem from "@/components/title-item";
import ImageItem from "@/components/image-item";

const screenwidth = window.innerWidth;
const ProjectGoals = memo((props) => {
  const [images, setImages] = useState({
    image1: {
      width: 0,
      height: 0,
    },
    image2: {
      width: 0,
      height: 0,
    },
    image3: {
      width: 0,
      height: 0,
    },
    image4: {
      width: 0,
      height: 0,
    },
    image5: {
      width: 0,
      height: 0,
    },
  });
  useEffect(() => {
    let imageObj = {};
    const baseW = screenwidth * 0.9 - 10;
    if (screenwidth < 1000) {
      imageObj = {
        image1: {
          width: screenwidth * 0.9,
          height: (screenwidth * 0.9) / 1.1,
        },
        image2: {
          width: screenwidth * 0.47,
          height: (screenwidth * 0.9) / 2,
        },
        image3: {
          width: screenwidth * 0.41,
          height: (screenwidth * 0.9) / 2,
        },
        image4: {
          width: screenwidth * 0.9,
          height: (screenwidth * 0.9) / 1.2,
        },
        image5: {
          width: screenwidth * 0.9,
          height: (screenwidth * 0.9) / 2,
        },
      };
    } else if (screenwidth < 1400) {
      imageObj = {
        image1: {
          width: baseW * 0.5,
          height: (baseW * 0.5) / 1.2,
        },
        image2: {
          width: baseW * 0.27,
          height: (baseW * 0.5) / 1.5,
        },
        image3: {
          width: baseW * 0.22,
          height: (baseW * 0.5) / 1.5,
        },
        image4: {
          width: baseW * 0.5,
          height: (baseW * 0.5) / 1.2,
        },
        image5: {
          width: baseW * 0.5,
          height: (baseW * 0.5) / 1.5,
        },
      };
    } else {
      imageObj = {
        image1: {
          width: "870",
          height: "726",
        },
        image2: {
          width: "510",
          height: "300",
        },
        image3: {
          width: "350",
          height: "300",
        },
        image4: {
          width: "510",
          height: "726",
        },
        image5: {
          width: "510",
          height: "300",
        },
      };
    }
    setImages(imageObj);
  }, []);
  return (
    <ProjectGoalsWrapper>
      <TitleItem title="Features of Starcharger" subtitle="" />
      <div className="img-box">
        <div className="main-img">
          <ImageItem
            imgName="ev-pgm1.jpg"
            imgDesc="Smart Charging"
            subtitle="Automatically adjusts charging speed based on grid demand, minimizing grid load and ensuring optimal energy use."
            width={images.image1.width}
            height={images.image1.height}
          />
          <div className="sub-img1">
            <ImageItem
              imgName="ev-pgm2.jpg"
              imgDesc="High Efficiency"
              subtitle="Delivers high-speed charging with customizable power output to suit user needs."
              width={images.image2.width}
              height={images.image2.height}
            />
            <ImageItem
              imgName="ev-pgm3.png"
              imgDesc="Enhanced Safety"
              subtitle="Protects against power surges and ensures safe charging."
              width={images.image3.width}
              height={images.image3.height}
            />
          </div>
        </div>
        <div className="sub-img2">
          <ImageItem
            imgName="ev-pgm4.png"
            imgDesc="Network Integration"
            subtitle={(<span>·Seamlessly connects with local Virtual Power Plant (VPP) networks to enable smart grid management.<br/><br/>·Supports grid stability through intelligent demand response, adjusting power consumption during high-demand periods.</span>)}
            width={images.image4.width}
            height={images.image4.height}
          />
          <ImageItem
            imgName="ev-pgm5.jpg"
            imgDesc="User-Friendly Design"
            subtitle="Compatible with most EV models, making it versatile and user-friendly."
            width={images.image5.width}
            height={images.image5.height}
          />
        </div>
      </div>
    </ProjectGoalsWrapper>
  );
});

export default ProjectGoals;
