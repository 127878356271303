/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-05-01 14:32:49
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-04 23:11:58
 */
import styled from "styled-components"

export const PowerwallWrapper = styled.div`
  background-color: #000;
  color: #fff;

  .content {
    margin: 0 auto;
  }

  @media screen and (max-width: 1200px) {
    .content {
      width: 90%;
      overflow-x: hidden;
    }
  }
  @media screen and (min-width: 1200px) {
    .content {
      width: 100%;
      max-width: 1400px;
    }
  }
`
